@import "_variables_flatly";
@import "~bootstrap/scss/bootstrap";
@import '~bootstrap-icons/font/bootstrap-icons.scss';
@import "_bootswatch_flatly.scss";

.w-10 {
  width: 10%;
}

.w-15 {
  width: 15%;
}

.w-20 {
  width: 20%;
}
